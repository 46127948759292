import React from 'react';
import './Modal.css';

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Privacy Policy</h2>
        <p>
          Last Updated: 29th Sep 2024
        </p>
        <p>
          Fotofest is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our website, www.thefotofest.com, and our services. By accessing or using our Site and services, you agree to the terms of this Privacy Policy.
        </p>
        <h3>1. Information We Collect</h3>
        <p>
          We collect information in the following ways:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> When you voluntarily provide it to us, such as when you contact us, subscribe to a newsletter, or make a purchase. Personal Information may include: Name, Email address, Mailing address, Payment information, and any other information you choose to provide.</li>
          <li><strong>Usage Data:</strong> We automatically collect information when you interact with our Site, such as IP address, Browser type, Device type, Pages viewed and time spent on the Site, Links clicked, Referring/exit pages.</li>
          <li><strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and other tracking technologies to collect information about your use of the Site, improve your experience, and provide targeted advertising.</li>
        </ul>
        <h3>2. How We Use Your Information</h3>
        <p>We may use the information we collect for the following purposes:</p>
        <ul>
          <li>To provide, maintain, and improve our services</li>
          <li>To process transactions and send related information</li>
          <li>To communicate with you about updates, promotions, and more</li>
        </ul>
        <h3>3. Data Security</h3>
        <p>
          We use reasonable security measures to protect the information we collect. However, no security system is completely secure.
        </p>
        {/* Add the rest of your Privacy Policy content here */}
        <button onClick={onClose} className="close-button-modal">Close</button>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
