import React from 'react';
import './HowItWorks.css'; // Import CSS for styling
import contactIcon from "../images/contact.png";
import personalizedgalleryIcon from "../images/personalizedgallery.png"
import placecardIcon from "../images/placecard.png"
import memoriesIcon from "../images/memories.png"


const steps = [
  {
    stepNumber: 1,
    title: "Contact us",
    description: "Contact us with desired web digital gallery packet, landing photo for gallery and the date of event",
    imgSrc: contactIcon // Replace with your actual image path
  },
  {
    stepNumber: 2,
    title: "Personalized gallery",
    description: "We will email you within 10 minutes with personalized gallery for you to log in.",
    imgSrc: personalizedgalleryIcon // Replace with your actual image path
  },
  {
    stepNumber: 3,
    title: "Setup your place-card",
    description: "Choose one of our place-cards that we will personalize. Then print as many as you need to place around your wedding.",
    imgSrc: placecardIcon // Replace with your actual image path
  },
  {
    stepNumber: 4,
    title: "Enjoy the memories",
    description: "During your event guests can scan your QR code to upload and share all their memories. After your event check back your gallery to access all your amazing photos.",
    imgSrc: memoriesIcon // Replace with your actual image path
  },
];

const HowItWorks = () => {
  return (
    <section className="how-it-works-section">
      <h2>How It Works</h2>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div className="step-card" key={index}>
            <div className="step-image">
              <img src={step.imgSrc} alt={`Step ${step.stepNumber}`} />
            </div>
            <div className="step-number">{step.stepNumber}</div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
