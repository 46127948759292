import React, { useState } from 'react';
import './UsernameModal.css';  // Assume some basic styling for the modal
import axios from "axios";

function UsernameModal({ isOpen, onClose, onSubmit }) {
  const [username, setUsername] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (event) => {

    event.preventDefault();
    axios
      .get(`username/`,{
        params: {
          userName: username,
        }
      })
      .then((response) => {
        //   setImageUrls(response.data);
        // console.log("Success:", response.data);

        onClose();  // Close the modal after submission

        onSubmit(username);
        //   setSelectedDate(Object.keys(response.data.image_urls)[0]);
      })
      .catch((error) => {
        console.error("There was an error fetching the room!", error);
      });
    // axios.post('/upload/${key}', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //   .then((response) => {
    //     console.log('Success:', response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Enter Your Username</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default UsernameModal;
