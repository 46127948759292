import React from 'react';
import './Modal.css'; // We will reuse the same modal styles

const TermsOfServiceModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Terms of Service</h2>
        <p>
          Last Updated: 28th Sep 2024
        </p>
        <h3>1. Acceptance of Terms</h3>
        <p>
          By accessing or using our services, you agree to be bound by these Terms of Service. If you do not agree to these terms, you may not use our services.
        </p>
        <h3>2. Use of the Services</h3>
        <p>
          You agree to use our services only for lawful purposes and in accordance with these Terms. You are responsible for your actions while using our services.
        </p>
        <h3>3. Changes to the Terms</h3>
        <p>
          We reserve the right to modify these Terms at any time. If we make significant changes, we will notify you through our services or via email.
        </p>
        <h3>4. Disclaimer of Warranties</h3>
        <p>
          We provide our services "as is" without any warranties. We do not guarantee the availability, accuracy, or reliability of the services.
        </p>
        {/* Add more sections of your Terms of Service here */}
        <button onClick={onClose} className="close-button-modal">Close</button>
      </div>
    </div>
  );
};

export default TermsOfServiceModal;
