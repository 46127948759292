import React, { useState } from "react";
import "./Footer.css";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import TermsOfServiceModal from "./TermsOfServiceModal";
import CookiesPolicyModal from "./CookiesPolicyModal";
import weddingIcon from "../images/wedding.png";
import birthdayIcon from "../images/birthday.png";
import corporateIcon from "../images/corporate.png";
import graduationIcon from "../images/graduation.png";
import engagementIcon from "../images/engagement.png";
import baptismIcon from "../images/baptism.png";

const Footer = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };

  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };
  const [isTermsOfServiceModalOpen, setIsTermsOfServiceModalOpen] =
    useState(false);

  const openTermsOfServiceModal = () => {
    setIsTermsOfServiceModalOpen(true);
  };

  const closeTermsOfServiceModal = () => {
    setIsTermsOfServiceModalOpen(false);
  };
  const [isCoockieModalOpen, setIsCoockieModalOpen] = useState(false);

  const openCoockieModal = () => {
    setIsCoockieModalOpen(true);
  };

  const closeCoockieModal = () => {
    setIsCoockieModalOpen(false);
  };
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <p>thefotofest@gmail.com</p>
          <div className="footer-social-icons">
            <a href="#" aria-label="Facebook">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" aria-label="Pinterest">
              <i className="fab fa-pinterest"></i>
            </a>
          </div>
          <p>© Copyright 2024 The FotoFest</p>
        </div>

        <div className="footer-center">
          <p>
              <img className="footer-icon-text" alt="fotofest" src={weddingIcon} />Weddings</p>
          <p><img className="footer-icon-text" alt="fotofest" src={birthdayIcon} />Birthdays</p>
          <p><img className="footer-icon-text" alt="fotofest" src={corporateIcon} />Corporate Events</p>
          <p><img className="footer-icon-text" alt="fotofest" src={graduationIcon} />Graduations</p>
          <p><img className="footer-icon-text" alt="fotofest" src={baptismIcon} />Memorials and Celebrations of Life</p>
          <p><img className="footer-icon-text" alt="fotofest" src={engagementIcon} />Engagement Parties</p>
        </div>

        <div className="footer-right">
          {/* <div className="country-region">
            <p>Country/Region</p>
            <button className="region-button">Croatia | EUR €</button>
          </div>
          <div className="payment-methods">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/a4/American_Express_logo_%282018%29.svg"
              alt="American Express"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Apple_Pay_logo.svg/1024px-Apple_Pay_logo.svg.png"
              alt="Apple Pay"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/5e/MasterCard_Logo.svg"
              alt="MasterCard"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/39/PayPal_logo.svg"
              alt="PayPal"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/f3/Visa_2014_logo_detail.svg"
              alt="Visa"
            />
          </div> */}
          <div className="footer-links">
            <button className="open-modal-button" onClick={openPrivacyModal}>
              Privacy Policy
            </button>
            <PrivacyPolicyModal
              isOpen={isPrivacyModalOpen}
              onClose={closePrivacyModal}
            />

            <button
              className="open-modal-button"
              onClick={openTermsOfServiceModal}
            >
              Terms of Service
            </button>
            <TermsOfServiceModal
              isOpen={isTermsOfServiceModalOpen}
              onClose={closeTermsOfServiceModal}
            />

            <button className="open-modal-button" onClick={openCoockieModal}>
              Cookie Policy
            </button>
            <CookiesPolicyModal
              isOpen={isCoockieModalOpen}
              onClose={closeCoockieModal}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
