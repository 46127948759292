import React, { useState, useRef, useEffect } from "react";
import './LandingPage.css';

const LandingPage = ({roomName, roomDate, landingUrl}) => {
  return (
    <div className="landing-container">
      <header className="header-landing">
        <h1 className="header-title">{roomName}</h1>
      </header>
      <div className="image-container">
        <img
          src={landingUrl} // Replace with actual image path
          alt="Wedding Photo"
          className="main-image"
        />
      </div>
      <p className="header-date">{roomDate}</p>
    </div>
  );
};

export default LandingPage;