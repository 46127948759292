import React, { useState, useRef, useEffect } from "react";
import "./HomePricingScroller.css";
import largeGallery from "../images/LargeGallery.png"; // Importing images from the src folder
import mediumGallery from "../images/MediumGallery.png";
import smallGallery from "../images/SmallGallery.png";
const HomePricingScroller = () => {
  const pricingOptions = [
    {
      name: "Large",
      imgSrc: largeGallery, // Update with the actual image path
      descriptions: [
        "- For large-scale events like corporate functions or weddings.",
        "- We provide a comprehensive solution to document and showcase every aspect.",
        "- 8GB Of Space (~1600 images).",
        "- 120 users profile.",
        "- 1 Week for uploading image.",
      ],
    },
    {
      name: "Medium",
      imgSrc: mediumGallery,
      descriptions: [
        "- Ideal for medium-sized celebrations such as bigger parties or company gatherings.",
        "- Our service ensures all significant details are remembered.",
        "- 4GB Of Space (~800 images).",
        "- 80 users profile.",
        "- 3 days for uploading image.",
      ],
    },
    {
      name: "Small",
      imgSrc: smallGallery, // Update with the actual image path
      descriptions: [
        "- Perfect for intimate gatherings like birthdays or small parties.",
        "- Capture every smile and special moment with ease.",
        "- 1GB Of Space (~200 images).",
        "- 40 users profile.",
        "- Upload image on event day only.",
      ],
    },
  ];

  useEffect(() => {
    function handleLoad() {

      const scrollers = document.querySelectorAll(".scroller");

      // If a user hasn't opted in for recuded motion, then we add the animation
      if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        addAnimation();
      }

      function addAnimation() {
        scrollers.forEach((scroller) => {
          // add data-animated="true" to every `.scroller` on the page
          scroller.setAttribute("data-animated", true);

          // Make an array from the elements within `.scroller-inner`
          const scrollerInner = scroller.querySelector(".scroller__inner");
          const scrollerContent = Array.from(scrollerInner.children);

          // For each item in the array, clone it
          // add aria-hidden to it
          // add it into the `.scroller-inner`
          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        });
      }
    }
    // window.addEventListener("load", handleLoad);
    handleLoad();
    handleLoad();
    }, []);
  return (
    <section className="pricing">
      <div className="scroller" data-direction="right" data-speed="slow">
        {/* Render the boxes twice to create the looping effect */}
        <div className="scroller__inner">
          {pricingOptions.map((option, index) => (
            <div className="pricing-option" key={index}>
              <div className="pricing-option-inner">
                <div className="option-title">{option.name}</div>
                <img
                  className="option-img"
                  alt={option.imgSrc}
                  src={option.imgSrc} // Replace with your image path
                />
                {option.descriptions.map((desc, descIndex) => (
                  <div className="option-desc" key={descIndex}>
                    {desc}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomePricingScroller;
