import React, { useState } from "react";

import "./ImagesUploadButton.css";
import axios from "axios";
import { getCsrfToken } from './csrf';  // Import the CSRF token function

function ImagesUploadButton({ roomKey, setLoadingModal }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    // Update the state with the selected files
    setSelectedFiles([...event.target.files]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    const csrfToken = getCsrfToken();  // Retrieve CSRF token from cookies
    setLoadingModal(true); // Hide the loading modal
    axios
      .post(`/upload/${roomKey}/`, formData, {
        headers: {
          'X-CSRFToken': csrfToken,  // Send the CSRF token in the headers
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, 
      })
      .then((response) => {
        window.location.reload(); // This will refresh the page
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <input
        type="file"
        id="fileInput"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the default file input
      />
      {/* <button
        type="button"
        className="upload-button"
        onClick={() => document.getElementById("fileInput").click()}
      >
        {selectedFiles.length > 0 ? (
          <>
            <span>{selectedFiles.length}</span>
            <span className="arrow-up">↑</span>
          </>
        ) : (
          <span className="plus-sign">+</span>
        )}
      </button> */}
      {selectedFiles.length == 0 && (
        <button
          type="button"
          className="upload-button"
          onClick={() => document.getElementById("fileInput").click()}
        >
          <span className="plus-sign">+</span>
        </button>
      )}
      {selectedFiles.length > 0 && (
        <button type="submit" className="submit-button">
          <span>({selectedFiles.length}) Upload</span>
        </button>
      )}
    </form>
  );
}

export default ImagesUploadButton;
