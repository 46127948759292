import React from "react";
import "./Home.css";
import logo from "./images/FotoFestLogoTransparentBackgroundedit.png"; // Replace with your logo path
import logoText from "./images/FotoFestLogoTransparentBackgroundtext.png"; // Replace with your logo path
import cloudComputingImage from "./images/cloud-computing.png"; // Replace with your logo path
import websiteImage from "./images/website.png"; // Replace with your logo path
import socialMediaImage from "./images/social-media.png"; // Replace with your logo path
import qrcodeImage from "./images/qr-code.png"; // Replace with your logo path
import Lottie from "lottie-react";
import qrCodeScanAnimation from "./animations/ScanQrCode.json"; // Replace with your lottie JSON file
import pageGalleryAnimation from "./animations/PageGallery.json"; // Replace with your lottie JSON file
import HomePricingScroller from "./homeComponents/HomePricingScroller";
import ContactForm from "./homeComponents/ContactForm";
import Footer from "./homeComponents/Footer";
import HowItWorks from "./homeComponents/HowItWorks";

function Home() {
  return (
    <div className="home">
      <header className="header">
        <div className="logo-header">
          <img src={logo} alt="Fotofest Logo" className="logo" />
          <img src={logoText} alt="Fotofest Logo" className="logoText" />
        </div>
        <a href="#contact" className="contact">
          Contact
        </a>
      </header>

      <main className="main">
        <h1>
          Create a gallery for your special event.
          <br />
          Upload photos and share them with your guests!
        </h1>

        <div className="gallery-instructions">
          <div className="section">
            <div className="phone">
              <h2>Scan QR code & Upload photos</h2>
              <p>
                Use your smartphone or a QR-enabled device to scan the code.
                You'll be directed to a web gallery where you can add your
                photos from the event!
              </p>
            </div>
            <Lottie
              className="qr-image"
              animationData={qrCodeScanAnimation}
              loop={true}
            />
          </div>
          <div className="section">
            <Lottie
              className="qr-image"
              animationData={pageGalleryAnimation}
              loop={true}
            />
            <div className="phone">
              <h2>Visit and View</h2>
              <p>
                Come to your gallery and see your work alongside others. Watch
                as the gallery grows and changes with contributions from your
                guests!
              </p>
            </div>
          </div>
        </div>
        <div className="triangle-container-full">
          <div className="grid-container">
            <div className="grid-item fade-in" id="info1">
              {" "}
              <img className="icon-text" alt="fotofest" src={qrcodeImage} />
              Easy to access web gallery for everyone
            </div>
            <div className="grid-item fade-in" id="info2">
              {" "}
              <img className="icon-text" alt="fotofest" src={websiteImage} />
              All pictures and videos in one gallery
            </div>
            <div className="grid-item fade-in" id="info3">
              {" "}
              <img className="icon-text" alt="fotofest" src={socialMediaImage} />
              No Sign in needed
            </div>
            <div className="grid-item fade-in" id="info4">
              {" "}
              <img className="icon-text" alt="fotofest" src={cloudComputingImage} />
              Download from gallery at any time
            </div>
          </div>
        </div>
      </main>
      <h3>Packets</h3>
      <HomePricingScroller/>

      <HowItWorks />
      <ContactForm />
      <Footer/>
    </div>
  );
}

export default Home;
