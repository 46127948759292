import React from 'react';
import './Modal.css'; // We will reuse the same modal styles

const CookiesPolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Cookies Policy</h2>
        <p>
          Last Updated: 28th Sep 2024
        </p>
        <h3>1. What Are Cookies?</h3>
        <p>
          Cookies are small text files that are stored on your device when you visit a website. They help us understand how you use our services and improve your experience.
        </p>
        <h3>2. How We Use Cookies</h3>
        <p>
          We use cookies to:
        </p>
        <ul>
          <li>Analyze site traffic and usage</li>
          <li>Store your preferences and settings</li>
          <li>Provide personalized content and advertising</li>
        </ul>
        <h3>3. Managing Cookies</h3>
        <p>
          You can manage your cookie preferences through your browser settings. However, disabling cookies may affect your ability to use some features of our services.
        </p>
        <h3>4. Third-Party Cookies</h3>
        <p>
          We may also use third-party cookies to track your online behavior across different websites and deliver targeted advertising.
        </p>
        <button onClick={onClose} className="close-button-modal">Close</button>
      </div>
    </div>
  );
};

export default CookiesPolicyModal;
