import React, {useEffect} from 'react';
import Gallery from './components/Room';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import myIcon from './components/images/FotoFestLogoTransparentBackgroundedit.png'; // Adjust the path

// function KeyPage() {
//   const { key } = useParams();
//   return <Gallery keyToCheck={key} />;
// }
function App() {

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = myIcon;
    document.head.appendChild(link);
    
    // Optional: Clean up if necessary
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  return (

    <Router>
      <Routes>
         <Route path="/" element={<Home />} />
        <Route path="/room/:key" element={<Gallery />} />
      </Routes>
    </Router>
  );
}

export default App;


