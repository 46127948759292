import React from "react";
import "./ContactForm.css";

const ContactForm = () => {
  const handleEmailClick = () => {
    window.location.href =
      "mailto:thefotofest@gmail.com?subject=Contact%20Us&body=Hello,";
  };
  return (
    <section className="get-in-touch">
      <h2>Get in Touch</h2>
      <p>
        We would love to hear more about your upcoming event and discuss how our
        digital gallery solution can help you.
      </p>
      <p>
        Contact us via email and we’ll get back to you with personalized
        recommendations.
      </p>
      <p>
        Let us help you create a stunning visual experience for your event, and
        give your guests a lasting impression they’ll love!
      </p>
      <p> Reach us today and make your event unforgettable.</p>
      <button className="say-hello-button"  onClick={handleEmailClick}>Contact us</button>
    </section>
  );
};

export default ContactForm;
