import React, { useRef, useEffect, useState } from "react";
import "./DateScroller.css";

function DateScroller({handleDateClickFun, dataObj}) {
  const scrollRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(Object.keys(dataObj.image_urls)[0]);
  let isDown = false;
  let startX;
  let scrollLeft;
  const handleMouseDown = (e) => {
    isDown = true;
    scrollRef.current.classList.add('active');
    startX = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft = scrollRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
    scrollRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    isDown = false;
    scrollRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Scroll-fast multiplier
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    handleDateClickFun(date);
  };

  // const stickyRef = useRef(null); // Create a reference to the sticky element
  const [originalOffset, setOriginalOffset] = useState(0);

  useEffect(() => {
    const stickyElement = scrollRef.current;

    // Set the original offset of the sticky element after it mounts
    setOriginalOffset(stickyElement.offsetTop);

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > originalOffset) {
        stickyElement.style.position = 'fixed';
        stickyElement.style.top = '0';
      } else {
        stickyElement.style.position = 'relative';
      }
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [originalOffset]); // Add originalOffset to the dependency array

  return (
    <div
      ref={scrollRef} 
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      // Ensure ref is properly attached here
      className={`date-scroll ${
        Object.keys(dataObj.image_urls).length > 4 ? "scrollable" : ""
      }`}
    >
      {Object.keys(dataObj.image_urls).map((date) => (
        <div
          key={date}
          className={`date-item ${selectedDate === date ? "active" : ""}`}
          onClick={() => {
            selectedDate != date && handleDateClick(date);
          }}
        >
          {date}
        </div>
      ))}
    </div>
  );
}

export default DateScroller;
