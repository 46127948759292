import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FaDownload } from "react-icons/fa"; // Import a download icon, e.g., from react-icons

import { useParams } from "react-router-dom";
import "./Room.css";
import UsernameModal from "./roomComponents/UsernameModal";
import LoadingModal from "./roomComponents/LoadingModal";
import ImagesUploadButton from "./roomComponents/ImagesUploadButton";
import DateScroller from "./roomComponents/DateScroller";
import LandingPage from "./roomComponents/LandingPage";

function Gallery() {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  // const [image_urls, setRoom] = useState(null);
  const { key } = useParams();
  useEffect(() => {
    axios
      .get(`/api/rooms/${key}/`)
      .then((response) => {
        setData(response.data);
        setSelectedDate(Object.keys(response.data.image_urls)[0]);

        if (response.data.user.name != null) {
          setUsername(response.data.user);
          setIsModalOpen(false);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the room!", error);
      });
  }, [key]);

  useEffect(() => {
    function waitForImagesToLoad(callback) {
      // const images = document.querySelectorAll("img"); // Select all images on the page
      const images = document.querySelectorAll(".photo");
      let loadedImagesCount = 0;
      const totalImages = images.length;

      if (totalImages === 0) {
        // If there are no images, run the callback immediately
        callback();
        return;
      }

      images.forEach((container) => {
        // If the image is already loaded, increase the count
        const style = window.getComputedStyle(container);
        const backgroundImage = style.backgroundImage
          .slice(4, -1)
          .replace(/"/g, "");

        // Create an image object and load the background image
        const img = new Image();
        img.src = backgroundImage;
        if (img.complete) {
          loadedImagesCount++;
          if (loadedImagesCount === totalImages) {
            callback(); // If all images are loaded, trigger the callback
          }
        } else {
          // Attach an event listener for images that are still loading
          img.addEventListener("load", () => {
            loadedImagesCount++;
            if (loadedImagesCount === totalImages) {
              callback(); // If all images are loaded, trigger the callback
            }
          });

          // Handle error case if an image fails to load
          img.addEventListener("error", () => {
            loadedImagesCount++;
            if (loadedImagesCount === totalImages) {
              callback(); // If all images are loaded or errored, trigger the callback
            }
          });
        }
      });
    }
    let imagesInRow;
    if (window.innerWidth < 700) {
      imagesInRow = 2;
    } else if (window.innerWidth < 900) {
      imagesInRow = 3;
    } else {
      imagesInRow = 4;
    }
    const adjustGridRows = () => {
      const imageContainers = document.querySelectorAll(".photo");

      const margin = window.innerWidth * 0.9;
      const marginSmall = window.innerWidth * 0.05;
      const paddingBetweenPictures = margin * 0.01;
      const width = (margin - paddingBetweenPictures * 3) / imagesInRow;
      imageContainers.forEach((container) => {
        // Extract the background image URL
        const style = window.getComputedStyle(container);
        const backgroundImage = style.backgroundImage
          .slice(4, -1)
          .replace(/"/g, "");

        // Create an image object and load the background image
        const img = new Image();
        img.src = backgroundImage;
        const ratio = img.width / img.height;
        const height = width / ratio;

        container.style.width = `${width}px`;
        container.style.height = `${height}px`;
      });

      const imageContainersCopy = document.querySelectorAll(".photo");

      let i = 0;
      let k = 0;
      let row = 0;
      let rowsMax = imageContainersCopy.length / imagesInRow + 7;
      let rowTemp = 0;
      let column = 0;
      let images = [];
      for (let i = 0; i < rowsMax; i++) {
        images[i] = []; // Create an empty subarray for each row
        for (let j = 0; j < imagesInRow; j++) {
          images[i][j] = null; // Initialize each cell with null (or any other value)
        }
      }
      imageContainersCopy.forEach((container, i) => {
        if (rowTemp < row) {
          rowTemp++;
          // images[row] = []; // Create an empty subarray for each row
        }
        while (true) {
          if (images[row][column] == null) {
            images[row][column] = imageContainersCopy[i];
            // Create an image object and load the background image
            const style = window.getComputedStyle(container);
            const backgroundImage = style.backgroundImage
              .slice(4, -1)
              .replace(/"/g, "");
            const img = new Image();
            img.src = backgroundImage;

            if ((img.height > img.width) & (row + 1 <= rowsMax)) {
              rowTemp++;
              // images[row + 1] = []; // Create an empty subarray for each row
              // images[row + 1][0] = null;
              images[row + 1][column] = imageContainersCopy[i];
            }
            break;
          }
          if (column >= imagesInRow - 1) {
            column = 0;
            row++;
          } else {
            column++;
          }
        }

        let top = 10; // Top position in pixels
        let left =
          width * column + paddingBetweenPictures + window.innerWidth * 0.05; // Left position in pixels
        let height = 0;
        let topString = 0;
        if (row > 0) {
          height = images[row - 1][column].getBoundingClientRect().height;

          // Get the top value as a number
          topString = images[row - 1][column].style.top; // This is a string like "50px"
        } else {
          height = 0;

          // Get the top value as a number
          topString = 10;
        }

        // Parse the top value to remove "px" and convert it to a number
        const topValue = parseFloat(topString) || 0; // If topString is empty, topValue will be 0

        // Calculate the new top value
        top = height + topValue + 10;

        if (column == 0 || column % imagesInRow == 0) {
          left = marginSmall; // Left position in pixels
        } else {
          left =
            width * Math.floor(column % imagesInRow) +
            paddingBetweenPictures * Math.floor(column % imagesInRow) +
            marginSmall;
        }
        container.style.top = `${top}px`;
        container.style.left = `${left}px`;
        setLoading(false);
        i++;
        if (column >= imagesInRow - 1) {
          column = 0;
          row++;
        } else {
          column++;
        }
      });
    };
    // Usage example
    waitForImagesToLoad(() => {
      setTimeout(adjustGridRows, 0); // window.addEventListener("DOMContentLoaded", adjustGridRowss);
      // window.onload = adjustGridRows;
      // // Cleanup to avoid memory leaks
      // return () =>
      //   window.removeEventListener("DOMContentLoaded", adjustGridRowss);
      // Your function here, e.g., adjustGridRows();
    });
  }, [data, selectedDate]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setLoading(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleUsernameSubmit = (name) => {
    setUsername(name);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageOrientation, setImageOrientation] = useState(null);
  const [imageDivOrientation, setImageDivOrientation] = useState(null);

  const [transition, setTransition] = useState(false);
  const handleImageClick = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      if (img.width > img.height) {
        setImageOrientation("full-height");
        setImageDivOrientation("ninety-height");
      } else {
        setImageOrientation("full-height");
        setImageDivOrientation("ninety-height");
      }

      setTimeout(() => setSelectedImage(imageUrl), 300);
    };
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  if (!data) return <div>Loading...</div>; // Handle null data
  if (!data.room) return <div>No room data available</div>; // Handle undefined room
  return (
    <div className="room">
      <LoadingModal show={loading} />
      <LandingPage roomName={data.room.name} roomDate={data.room.startEvent} landingUrl={data.room.landingPhoto}/>
      <UsernameModal
        onSubmit={handleUsernameSubmit}
        isOpen={isModalOpen}
        onClose={closeModal}
      ></UsernameModal>
      {data.is_within_days && <ImagesUploadButton roomKey={key} setLoadingModal={setLoading} />}
      {/* <p>Description: {data.room.description}</p>
      <p>User: {data.user.name}</p>
      <p>Uploadable: {data.is_within_days ? "Yes" : "No"}</p> */}
      <DateScroller handleDateClickFun={handleDateClick} dataObj={data} />

      <div className="image-gallery">
        {Array.isArray(data.image_urls[selectedDate]) &&
          data.image_urls[selectedDate]?.map((url, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `url(${url})`,
              }}
              alt={`Image taken on ${selectedDate}`}
              className={`photo`}
              onClick={() => handleImageClick(`${url}`)}
            >
              <a
                href={url}
                download
                className="download-icon"
                title="Download Image"
                onClick={(e) => e.stopPropagation()} // Prevents modal from opening
              >
                <FaDownload />
              </a>
            </div>
          ))}
      </div>

      {selectedImage && (
        <div className="modal">
          <a
            href={selectedImage}
            download
            className="download-button-image-modal"
            title="Download Image"
            onClick={(e) => e.stopPropagation()} // Prevents modal from opening
          >
            <FaDownload />
          </a>
          <button
            className="close-button-image-modal"
            onClick={handleCloseModal}
          >
            Close
          </button>
          <div className={`image-modal-content ninety-height`}>
            <img
              src={selectedImage}
              alt={selectedImage.alt}
              className="full-height"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;
