import React from 'react';
import './LoadingModal.css';

function LoadingModal({ show }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="loader"></div>
        <p>Please wait...</p>
      </div>
    </div>
  );
}

export default LoadingModal;
